import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import { About, PostListing, ProjectListing, Wrapper, Title, Wave } from '../components'
import website from '../../config/website'
import { LocaleContext } from '../components/Layout'
import SEO from '../components/SEO'

import Div100vh from 'react-div-100vh'

const Hero = styled.header`
  background-color: ${props => props.theme.colors.bg};
  display: flex;
  align-items: center;
`

const HeroInner = styled(Wrapper)`
  height: 100%;
  padding-top: 13rem;
  padding-bottom: 13rem;
  font-family: 'Montserrat', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial',
  sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  h1 {
    text-align: center;
    font-size: 9rem;
    margin-bottom: 2rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.l}) {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    h1 {
      font-size: 4.209rem !important;
    }
    margin: auto;
    padding-top: 0;
    padding-bottom: 0;
    text-align: center;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }
`

const HeroText = styled.div`
  font-size: 1.7rem;
  line-height: 1.4;
  margin-bottom: 2rem;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    font-size: 1.4rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 1.25rem;
  }
`

const WaveWrapper = styled.div`
  position: absolute;
  top: 60px;
  width: 100%;
  height: 60px;
  overflow: hidden;
  background: linear-gradient(to bottom, ${props => props.theme.colors.secondary} 50%, transparent 0);
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    bottom: 60px;
    top: unset;
    transform: scaleY(-1) scaleX(-1);
  }
`

const IndexWrapper = Wrapper.withComponent('main')

const Index = ({ data: { homepage, social, posts, projects }, pageContext: { locale }, location }) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]

  return (
    <>
      <SEO pathname={location.pathname} 
      locale={locale} 
      pageType={''}
      />
      <Hero>
        <Div100vh style={{height: 'calc(100rvh - 60px)'}} css={css`
          width: 100%;
          text-align: center;
          /*${props => props.theme.headerHeight.m}*/
        `}>
          <HeroInner>
            {/*<h1>{homepage.data.title.text}</h1>*/}
            <h1>Pedro.</h1>
            <HeroText dangerouslySetInnerHTML={{ __html: homepage.data.content.html }} />
          </HeroInner>
        </Div100vh>
      </Hero>
      <WaveWrapper>
        <Wave animationSpeed={"2s"} type={1}/>
        <Wave animationSpeed={"3s"} type={2}/>
      </WaveWrapper>
      <About picture={homepage.data.image.localFile.childImageSharp.fluid}/>
      <IndexWrapper id={website.skipNavId} style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
        <Title>{i18n.pages.projects.recent}</Title>
        <ProjectListing projects={projects.edges} />
        <Title>{i18n.pages.blog.recent}</Title>
        <PostListing posts={posts.edges} />
      </IndexWrapper>
    </>
  )
}

export default Index

Index.propTypes = {
  data: PropTypes.shape({
    posts: PropTypes.object.isRequired,
    homepage: PropTypes.object.isRequired,
    social: PropTypes.object.isRequired,
    projects: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query IndexQuery($locale: String!) {
    homepage: prismicHomepage(lang: { eq: $locale }) {
      data {
        title {
          text
        }
        content {
          html
        }
        image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 300, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
    social: allPrismicHeroLinks(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          data {
            body {
              primary {
                label {
                  text
                }
                link {
                  url
                }
              }
            }
          }
        }
      }
    }
    posts: allPrismicPost(limit: 4, sort: { fields: [data___date], order: DESC }, filter: { lang: { eq: $locale } }) {
      edges {
        node {
          uid
          data {
            title {
              text
            }
            description
            date(formatString: "DD.MM.YYYY")
            categories {
              category {
                document {
                  uid
                  data {
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
    projects: allPrismicProject(limit: 4, sort: { fields: [data___date], order: DESC }, filter: { lang: { eq: $locale } }) {
      edges {
        node {
          uid
          data {
            title {
              text
            }
            description
            date(formatString: "DD.MM.YYYY")
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1200, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
            categories {
              category {
                document {
                  uid
                  data {
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
